@import "./../../styleSheets/fonts";

.strip {
  position: relative;
  padding-top: $fs-12px;
  padding-bottom: $fs-12px;
}

.strip-no-padding {
  @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
}
