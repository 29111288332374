.common-table {
  .common-table__thead {
    tr {
      font-family: $publicsans-semiBold !important;
      font-size: $fs-16px;
      background-color: $themeColor;
      color: $white;
      th {
        @include setPadding(
          $fs-8px !important,
          $fs-8px !important,
          $fs-8px !important,
          $fs-8px !important
        );
        border-bottom-width: 0 !important;
      }
    }
  }
  .common-table__tbody {
    tr {
      font-family: $publicsans-regular !important;
      font-size: $fs-14px;
      color: $black;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
      td {
        @include setPadding(
          $fs-6px !important,
          $fs-8px !important,
          $fs-6px !important,
          $fs-8px !important
        );
      }
    }
  }
}

.rdt_Table {
  height: auto !important;
}

.data-table {
  padding-bottom: $fs-16px !important;
  header {
    display: none;
  }
}

.data-table-container {
  header {
    display: block;
  }
}
