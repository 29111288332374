.common-form-control {
  font-family: $publicsans-semiBold !important;
  font-size: $fs-15px !important;
  color: $txtdblue !important;
  border-radius: $fs-4px !important;
  border-color: $gain-boro-white !important;
  height: $fs-40px !important;
  @include setFont(
    500 !important,
    normal !important,
    normal !important,
    normal !important,
    left !important
  );
  &:focus {
    color: $charcoal-grey !important;
    box-shadow: none !important;
    border-color: $gain-boro-white !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-13px !important;
  }
  @include mobileScreen {
    height: $fs-54px !important;
    font-size: $fs-24px;
  }
}

.common-form-control--left-radius-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.common-label {
  font-size: $fs-16px;
  font-family: $publicsans-semiBold;
  color: $dimGrey;
  margin-bottom: 0 !important;
  @include mobileScreen {
    font-size: $fs-16px;
  }
}

.help-block {
  color: $danger;
  margin-bottom: 0 !important;
  padding-top: $fs-4px;
}

.has-error {
  .form-control {
    border-color: $danger !important;
  }
}

.text-position {
  text-align: right;
  @include mobileScreen {
    text-align: left;
  }
}

textarea.form-control {
  @include mobileScreen {
    height: 10rem !important;
  }
  height: 12rem !important;
}

.input-form-group-prepend {
  border-radius: $fs-4px !important;
  border-color: $gain-boro-white !important;
  border-right: none !important;
  border: $fs-1px solid $gain-boro-white;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-dropdown {
  .input-dropdown-toggle {
    background-color: $transparent !important;
    font-family: $opensans-semiBold !important;
    font-size: $fs-15px !important;
    color: $black !important;
    border: none !important;
    @include mobileScreen {
      height: $fs-54px !important;
      font-size: $fs-24px;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}
.input-dropdown-menu {
  min-width: 4rem !important;
  padding: 0rem 0 !important;
}
.input-dropdown-menu-item {
  padding: 0.25rem 0.5rem !important;
  &:focus {
    background-color: $transparent !important;
    color: $black !important;
  }
}

.common-form-control--bt-br {
  border: none !important;
  border-bottom: 1px solid $gain-boro-white !important;
  border-radius: 0 !important;
  padding-bottom: 0 !important;
  padding-left: $fs-4px !important;
  padding-right: $fs-4px !important;
}

.tooltip_container {
  position: absolute;
  right: $fs-16px;
  cursor: pointer;
  @include mobileScreen {
    top: 0;
  }
}

.tooltip-inner {
  padding: $fs-4px !important;
  width: 8rem !important;
  background-color: $white !important;
  border: $fs-1px solid $gain-boro-white !important;
}
.tooltip-img {
  width: $fs-18px;
}

.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #ddd !important;
}
