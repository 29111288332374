@import "./Navigation/Navigation";
@import "./dashboard/patientInfo";

.border-9rem {
  border-radius: 9rem !important;
}

.orange-bg {
  // background-color: #4c7888db !important;
  background-color: #72d389 !important;
}

.orange-text {
  // color: #396170 !important;
  color: #72d389 !important;
}
.green-text {
  color: #72d389 !important;
}

.gray-text {
  // color: #344e79 !important;
  color: #72d389 !important;
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}

.height-90 {
  height: 90vh;
}
.height-80 {
  height: 80vh;
}
.table-style {
  border: 2px solid #c8d3d4 !important;
  border-radius: 5px !important;
  color: #000000 !important;
}
.table-color {
  // background-color: #396170 !important;
  background-color: #ffffff !important;
}

InputGroupText {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
.closeBtn,
button {
  // Remove focus around button
  outline: none !important ;
}

.headerTextSize {
  font-size: 1.5rem !important;
}
.confirmPasswordError {
  border: 1px solid #ff0000;
}

.green-tick {
  color: #018401 !important;
  // background-color: green !important;
  text-align: center !important;
}

.gray-tick {
  color: #8080806b !important;
}

.bottomButtonRef {
  position: relative !important;
  margin-top: 50% !important;
}

.bottomButtonHel {
  position: relative !important;
  margin-top: 15%;
}

.boldText {
  font-weight: bold !important;
}

.grayBg {
  background-color: #f7f7f7 !important;
}

.sideSpace {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
@media only screen and (max-width: 1279px) {
  .sideSpace {
    margin-left: 25% !important;
    margin-right: 25% !important;
  }
}
@media only screen and (max-width: 800px) {
  .sideSpace {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
@media only screen and (max-width: 600px) {
  .sideSpace {
    margin-left: 2% !important;
    margin-right: 2% !important;
  }
}
.floatButtonBottom {
  position: sticky !important;
  bottom: 0% !important;
}

.bottomShadow {
  border: bottom 2px solid #b7b7b8;
}

.borderRightGray {
  border-right: 3px solid #b3a5a582 !important;
}
.borderRightGreen {
  border-right: 3px solid #72d389 !important;
}
.borderRightBlue {
  border-right: 3px solid #87b5d0 !important;
}
.mt-90px {
  margin-top: 75px;
}

.mt-75px {
  margin-top: 75px;
}
.linkstyle {
  text-decoration: none !important;
  color: #000000 !important;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
.checkBoxStyle {
  width: 1.25rem !important;
  height: 1.25rem !important;
  margin-right: 2px !important;
}

.textareaStyle {
  height: 10rem !important;
}
@media only screen and (min-width: 400px) {
  .textareaStyle {
    height: 15rem !important;
  }
}
@media only screen and (min-width: 600px) {
  .textareaStyle {
    height: 10rem !important;
  }
}
@media only screen and (min-width: 1366px) {
  .textareaStyle {
    height: 10rem !important;
  }
}
// @media only screen and (min-width: 1280px) {
//   .textareaStyle {
//     height: 23rem !important;
//   }
// }
@media only screen and (min-width: 1440px) {
  .textareaStyle {
    height: 25rem !important;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 600px !important;
    @include desktopLarge{
      max-width: 500px !important;
    }
  }
}

// ------font for patient status------------
.status-text--small {
  font-size: $fs-14px !important;

  @include xsmall {
    font-size: $fs-11px !important;
  }
  @include phone {
    font-size: $fs-13-28px !important;
  }
  @include iphone {
    font-size: $fs-11px !important;
  }
  @include desktop {
    font-size: $fs-16px !important;
  }
  @include desktop-max {
    font-size: $fs-13px !important;
  }
  @include iPad-portrait {
    font-size: $fs-13-28px !important;
  }
}
.status-divider-plr {
  padding-left: 4px !important;
  padding-right: 4px !important;
  @include mobileScreen {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
