
//datapicker custom styles
.react-date-picker {
  width: 100%;
}
.react-date-picker__calendar {
  width: $fs-304px;
  max-width: $fs-304px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99 !important;
}
.react-calendar__navigation {
  height: $fs-42px !important;
  margin-bottom: 0 !important;
  border-bottom: $fs-1px solid $gain-boro-white;
}
.react-calendar__navigation button {
  min-width: $fs-32px !important;
  background: none !important;
  font-size: $fs-14px;
  font-family: $opensans-semiBold;
  color: $white !important;
  background-color: $themeColor !important;
  @include setFont(normal, normal, normal, normal, center);
}
.react-date-picker__button__input {
  padding: 0 $fs-14px !important;
  font-family: $opensans-semiBold;
}
.react-date-picker__button {
  display: flex;
  border: $fs-1px solid $transparent;
  height: auto;
  font-size: $fs-14px;
  border-radius: $fs-2px;
  background-color: $white;
  width: auto;
  padding: 0 $fs-4px !important;
  align-items: center !important;
}
.react-date-picker__button__icon {
  width: $fs-32px;
  &:focus {
    outline: none !important;
  }
}
.react-date-picker__button__input__input {
  &:invalid {
    outline: none !important;
    background-color: $transparent;
  }
  &:focus {
    outline: none !important;
    outline-offset: 0 !important;
  }
}
.react-calendar__month-view__weekdays__weekday {
  font-family: $opensans-semiBold;
  font-size: $fs-10px;
  color: $grey;
}
.react-calendar__tile {
  font-family: $opensans-semiBold !important;
  font-size: $fs-13px;
}
.react-date-picker__calendar .react-calendar {
  border-radius: $fs-5px;
  box-shadow: 0 $fs-10px $fs-20px 0 $transparent;
  background-color: $white;
  border: solid $fs-2px $gain-boro-white;
  width: $fs-304px;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: $grey;
}
.react-calendar__month-view__days__day--weekend {
  color: $grey;
}

.react-calendar__tile--active {
  background-color: $themeColor !important;
  color: $white;
}
button.react-calendar__tile--active:enabled:hover,
button.react-calendar__tile--active:enabled:focus {
  background: $themeColor;
  color: $white;
}
button.react-calendar__tile:enabled:hover,
button.react-calendar__tile:enabled:focus {
  background-color: $themeColor;
  color: $white;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: $themeColor !important;
  color: $white !important;
}
.react-calendar__tile--hasActive {
  background-color: $themeColor !important;
  color: $white !important;
}
.react-calendar__navigation button[disabled] {
  background-color: $transparent !important;
}
.react-calendar__month-view {
  @include setPadding($fs-12px, $fs-12px, $fs-12px, $fs-12px);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  @include setPadding($fs-20px !important, $fs-8px !important, $fs-20px !important, $fs-8px !important);
}
.react-calendar__month-view__days__day {
  border-radius: 50%;
  @include setPadding(0, 0, 0, 0);
  height: $fs-36px;
}
.react-date-picker__clear-button svg {
  width: $fs-15px !important;
  height: $fs-40px !important;
}
.react-date-picker__clear-button svg g {
  stroke: $gain-boro-white;
  &:focus {
    outline: none !important;
  }
}
.react-date-picker__calendar-button {
  .fa-calendar {
    &:before {
      line-height: $fs-40px !important;
    }
  }
}

.react-date-picker__wrapper {
  display: flex;
  border: $fs-1px solid $gain-boro-white !important;
  height: $fs-40px;
  font-size: $fs-14px;
  border-radius: $fs-4px;
  background-color: $white;
  width: 100%;
  @include mobileScreen{
    height: $fs-54px;
    font-size: $fs-16px;
  }
}

.react-date-picker__inputGroup__input {
  width: 1rem;
  text-align: center;
  &:focus {
    outline: none !important;
  }
}
.react-date-picker__inputGroup{
  padding: 0 $fs-8px !important;
}
