@import "colors";
@import "mixins";
@import "fonts";

.custom-theme-btn {
  font-family: $publicsans-medium;
  font-size: $fs-16px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-10px !important,
    $fs-8px !important,
    $fs-10px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $themeColor !important,
    $themeColor !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
        $white !important,
      //border-color
        $themeColor !important//color
    );
    border-color: $themeColor !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $themeColor !important
    );
    border-color: $themeColor !important;
  }
  &:disabled {
    @include setBtnColor(
      $chateauGreyColor !important,
      $chateauGreyColor !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $chateauGreyColor !important;
  }
}

.custom-grey-btn {
  font-family: $opensans-semiBold;
  font-size: $fs-16px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-10px !important,
    $fs-8px !important,
    $fs-10px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $white !important,
    $grey !important,
    $dimGrey !important
  );
  &:hover {
    @include setBtnColor(
      $dimGrey !important,
      $white !important,
      $white !important
    );
    border-color: $grey !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $dimGrey !important,
      $white !important,
      $white !important
    );
    border-color: $grey !important;
  }
  &:disabled{
    cursor: not-allowed;
  }
}

.custom-danger-btn {
  font-family: $publicsans-medium;
  font-size: $fs-16px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-10px !important,
    $fs-8px !important,
    $fs-10px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $white !important,
    $danger !important,
    $danger !important
  );
 
  &:hover {
    @include setBtnColor(
      $danger !important,
      $danger !important,
      $white !important
    );
    border-color: $grey !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $danger !important,
      $danger !important,
      $white !important
    );
    border-color: $grey !important;
  }
}
.custom-btn--small {
  @include setPadding(
    $fs-6px !important,
    $fs-8px !important,
    $fs-6px !important,
    $fs-8px !important
  );
  @include mobileScreen{
    @include setPadding(
      $fs-12px !important,
      $fs-8px !important,
      $fs-12px !important,
      $fs-8px !important
    );
  }
}

.btn-transparent {
  font-family: $opensans-semiBold;
  font-size: $fs-16px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-6px !important,
    $fs-6px !important,
    $fs-6px !important,
    $fs-6px !important
  );
  @include setBtnColor(
    $transparent !important,
    $transparent !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $transparent !important,
      $transparent !important,
      $white !important
    );
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $transparent !important,
      $transparent !important,
      $white !important
    );
  }
}

.common-dropdown-menu {
  //transform: translate3d(-115px, 60px, 0px) !important;
  cursor: pointer !important;
  padding: 0 !important;
  .dropdown-item {
    padding: 0 !important;
    font-family: $publicsans-semiBold;
    font-size: $fs-14px !important;
    cursor: pointer !important;
    @include setFont(500, normal, normal, normal, left);
    &:focus {
      background-color: $transparent !important;
      color: $black !important;
    }
    &:active {
      background-color: $transparent !important;
      color: $black !important;
    }
  }
  .dropdown-menu__link {
    @include setPadding(
      $fs-8px !important,
      $fs-16px !important,
      $fs-8px !important,
      $fs-16px !important
    );
    color: $black !important;
    text-decoration: none !important;
    align-items: center;
    &:focus {
      background-color: $transparent !important;
      color: $black !important;
    }
    &:active {
      background-color: $transparent !important;
      color: $black !important;
    }
  }
}

.common-btn-white {
  // background-color: $transparent !important;
  @include setBtnColor(
    $transparent !important,
    $transparent !important,
    $transparent !important
  );
  padding: 0 !important;
  border: none !important;
  &:focus {
    border: none !important;
    box-shadow: none !important;
  }
  &:disabled {
    cursor: default !important;
  }
}
