.render-page {
  margin-top: $fs-80px;
  @include mobileScreen{
    margin-top: $fs-96px !important;
  }
}

.render-page.top {
  margin-top: $fs-10px;
  @include mobileScreen{
    margin-top: $fs-96px !important;
  }
}




