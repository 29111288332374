.common-status-btn {
  font-family: $opensans-semiBold;
  background: $white;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: $fs-4px;
  @include setPadding($fs-12px, $fs-12px, $fs-12px, $fs-12px);
  @include mobileScreen{
    @include setPadding($fs-16px, $fs-12px, $fs-16px, $fs-12px);
  }
}
.processing-btn {
  color: $lightBlue;
  border-right: $fs-5px solid $lightBlue;
}

.success-btn {
  color: $themeColor;
  border-right: $fs-5px solid $themeColor;
}

.disabled-btn {
  color: $black;
  border-right: $fs-5px solid $grey;
}

