.internal-page__title {
  font-family: $publicsans-semiBold;
  color: $black;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.internal-section__title {
  font-family: $publicsans-medium;
  color: $themeColor;
  @include setFont(normal, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.opensans-semiBold {
  font-family: $publicsans-semiBold;
}

.opensans-Bold {
  font-family: $publicsans-bold;
}

.full-height {
  height: 100vh;
}

/*background color*/
.bg-white {
  background-color: $white;
}

.bg-theme-color {
  background-color: $themeColor !important;
}
.bg-light-white {
  background-color: $lightWhite;
}

.bg-grey-light {
  background-color: $greyLight;
}

/*color*/
.title-theme-color {
  color: $themeColor !important;
}
.title-black {
  color: $txtdblue !important;
  font-weight: normal !important;
  font-size: $fs-26px;
  line-height: 34px;
}
.title-white {
  color: $white;
}

.title-slategray {
  color: $slategray !important;
}

.title-charcoal-grey {
  color: $charcoal-grey !important;
}

.title-suvaGrey {
  color: $suvaGrey !important;
}
.title-danger {
  color: $danger !important;
}

.title-lightBlue {
  color: $lightBlue !important;
}

.title-dimGrey {
  color: $dimGrey !important;
}


.br-light-smoke {
  border: 2px solid $white-light-smoke !important;
}

.br-bt-light-smoke {
  border-bottom: 2px solid $white-light-smoke !important;
}

.br-light-smoke {
  border: 1px solid $gain-boro-white !important;
}

.br-4px {
  border-radius: $fs-4px;
}

.br-top-lr-4px {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.brc-danger {
  border-color: $danger !important;
  border: 1px solid $danger !important;
  &:focus {
    border: 1px solid $danger !important;
  }
}

.box-shadow {
  -webkit-box-shadow: -1px 1px 1px -17px rgba(221, 221, 221, 1);
  -moz-box-shadow: -1px 1px 1px -17px rgba(221, 221, 221, 1);
  box-shadow: -1px 1px 1px -17px rgba(221, 221, 221, 1);
}

.width_10 {
  width: 10%;
}
.width_15 {
  width: 15%;
}

.close-icon {
  width: $fs-32px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}
.modal-content-container {
  padding-top: $fs-20px !important;
  padding-bottom: $fs-32px !important;
}

.modal-header-container {
  background-color: $themeColor;
  @include setPadding($fs-12px, $fs-8px, $fs-12px, $fs-8px);
}

.card-contianer {
  background-color: $lightWhite;
  border: 1px solid $lightWhite;
  padding: $fs-8px $fs-12px;
  border-radius: $fs-4px;
  cursor: pointer !important;
  margin-bottom: $fs-8px;
  -webkit-box-shadow: 0px 4px 0px -1px rgba(221, 221, 221, 1);
  -moz-box-shadow: 0px 4px 0px -1px rgba(221, 221, 221, 1);
  box-shadow: 0px 4px 0px -1px rgba(221, 221, 221, 1);
  .title {
    font-family: $opensans-semiBold;
    font-size: $fs-16px;
    color: $black;
    font-weight: bold;
  }
  .sub-title {
    font-family: $opensans-semiBold;
    font-size: $fs-16px;
    color: $dimGrey;
  }
  @include mobileScreen {
    padding: $fs-8px $fs-8px !important;
    margin-bottom: $fs-12px;
  }
}
.status-active {
  font-family: $opensans-semiBold;
  font-size: $fs-14px;
  color: $themeColor;
  @include desktopLarge {
    font-size: $fs-12px;
  }
}
.status-inactive {
  font-family: $opensans-semiBold;
  color: $dimGrey;
  font-size: $fs-14px;
  @include desktopLarge {
    font-size: $fs-12px;
  }
}
.card-container__position {
  height: 66vh;
  min-height: 66vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.img-view-container {
  overflow: hidden;
  overflow-x: auto;
  flex-wrap: nowrap;
  margin: $fs-6px !important;
}

.img-container {
  min-width: $fs-112px;
  max-width: $fs-112px;
  height: $fs-96px;
  border: 2px solid #ddd;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    //object-fit: contain;
  }
  input[type="file"] {
    position: absolute;
    width: 7rem;
    height: 5rem;
    opacity: 0;
    cursor: pointer !important;
    z-index: 99;
  }
}

.sqaure-unchecked {
  border: 2px solid $themeColor;
  color: $white !important;
  border-radius: 4px;
}

.upload-icon__container {
  position: inherit;
  cursor: pointer;
}

.fw-800 {
  font-weight: 800;
}

.confirmation-content__container {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.active-btn {
  font-size: $fs-12px;
  color: $white;
  background-color: $themeColor;
  padding: $fs-3px $fs-8px;
  border-radius: $fs-4px;
}
.inactive-btn {
  font-size: $fs-12px;
  color: $white;
  background-color: $danger;
  padding: $fs-3px $fs-8px;
  border-radius: $fs-4px;
}

.swal-modal-warning-small {
  width: 20rem !important;
  padding: 2rem 1rem;
  .swal-icon {
    width: 3rem;
    height: 3rem;
    &:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    .swal-icon--warning__body {
      width: 0.25rem;
      height: 1.5rem;
      .swal-icon--warning__dot {
        margin-left: -3px;
      }
    }
  }
  .swal-text {
    font-family: $opensans-semiBold;
    font-size: $fs-14px;
    color: $warning;
    padding-top: 1rem;
    text-align: center;
  }
  .swal-footer {
    display: none;
  }
}

.swal-modal-succuess-small {
  width: 20rem !important;
  padding: 2rem 1rem;
  .swal-icon {
    &:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    .swal-icon--warning__body {
      width: 0.25rem;
      height: 1.5rem;
      .swal-icon--warning__dot {
        margin-left: -3px;
      }
    }
  }
  .swal-text {
    font-family: $opensans-semiBold;
    font-size: $fs-14px;
    color: $themeColor;
    padding-top: 1rem;
    text-align: center;
  }
  .swal-footer {
    display: none;
  }
}

.dp-none {
  display: none;
}

.dp-block {
  display: block;
}

.report_logo-container {
  width: 3.625rem;
}

.report-print_logo-container {
  width: 12rem;
  margin: auto;
  text-align: center;
  display: flex;
}

.max-width-100 {
  max-width: 100% !important;
}

.change-password-container {
  min-height: 85vh;
}

.change-password-form-container {
  box-shadow: 0 0.05rem 0.2rem rgba(0, 0, 0, 0.15) !important;
}

.report-print-container {
  border: $fs-4px solid $themeColor;
  border-radius: $fs-4px;
  height: 100%;
  margin-top: $fs-90px;
}

.video__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
  background-color: #e9ecef;
  border-radius: $fs-4px;
}

.zoom-img-container {
  width: 350px;
  height: 350px;
  @include mobileScreen {
    max-width: 100%;
    width: auto;
  }
  @include desktopLarge {
    height: 300px;
  }
}
.zoom-img-item {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.zoom-annontation-img-container {
  width: 350px !important;
  height: 350px !important;
  object-fit: contain;
  background-color: #ddd;
  @include mobileScreen {
    width: auto;
    max-width: 100%;
  }
}

.captured-image-container {
  max-width: 100%;
  max-height: 100%;
  height: 6rem;
}

.TransformComponent-module_content__TZU5O .zoom-img-container .img-figure img {
  pointer-events: none !important;
}

.TransformComponent-module_content__TZU5O
  .img-annonation-contianer
  .img-figure
  img {
  pointer-events: unset !important;
}

.report-image-container {
  max-width: 100%;
  max-height: 100%;
  height: 4rem;
}

.transform-img-container {
  position: relative;
  width: 350px;
  height: 350px;
}

.annonate-img-container {
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 9999;
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.custom-cursor {
  cursor: url("../img/pencil.png") 6 20, auto !important;
}

.text-underline {
  text-decoration: underline;
}

.icon-envelope {
  border: 1px solid $themeColor;
  border-radius: 50%;
  padding: $fs-16px;
}

.active-link:hover {
  color: $black !important;
}

.circle-loader {
  position: absolute;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 999;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border: $fs-4px solid $white-smoke;
  border-top: $fs-4px solid $themeColor;
  border-radius: 50%;
  width: $fs-36px;
  height: $fs-36px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fs-12px {
  font-size: $fs-12px !important;
}

.bg-grey-medium {
  background: #f9f9f9;
}

.full-width {
  width: 100%;
}

.fs-22px {
  font-size: $fs-22px;
}


.text-capitalize {
  text-transform: capitalize !important;
}

.dropzone {
  position: absolute;
  opacity: 0;
  z-index: 999;
  cursor: pointer !important;
  max-width: 6rem;
  min-width: 6rem;
  height: 6rem;
}

.header-width { box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);}

.title-blue { color: $txtdblue;}
.profile-icon { color: #FA812D;}
#root { background: $bg_color;}
.bg-org { background: $bg_orange !important; border: 1px solid $bg_orange !important;}
.btn-pad { padding: 5px 12px !important;}
.btn-padd {width: 166px}
.input-group input.form-control { background: #cbd5e180; height: auto; border-radius: 25px !important; font-family: $publicsans-regular; padding: 8px 35px 8px 20px !important;}
#tst-camp-list .input-group { position: relative;}
.input-group .input-group-text { background: none; padding: 0; border: none;}
.input-group .input-group-text svg { color: $txtdblue;}
.input-group .input-group-append { right: 15px; top: 13px; border: none; z-index: 8; position: absolute;}

.rdt_TableHeader { border-radius: .6rem; box-shadow: 0 3px 5px #0000000b; margin-bottom: 20px; border: 1px solid #e2e8f0;}
// .imYJun { background: #1E293B !important; font-family: $publicsans-semiBold !important; }
// .eJuoXA { color: #334155 !important; font-family: $publicsans-regular !important;}
.rdt_TableCol { border-right: 1px solid rgba(0, 0, 0, 0.1) !important; background: #EDF1F6 !important; color: #334155 !important; padding-left: 0.65rem !important;  padding-right: 0.65rem !important;  }
.rdt_TableCol_Sortable { font-family: $publicsans-semiBold !important; }
.rdt_TableCell { color: #647691 !important; font-family: $publicsans-regular !important; font-size: 13px; padding-top: 10px;
  padding-bottom: 10px; padding-left: 0.65rem !important;  padding-right: 0.65rem !important; border-right: 1px solid rgba(0,0,0,0.08) !important;}
.rdt_TableCell:hover { color: #037DE8 !important;}
.rdt_Pagination span { font-family: $publicsans-semiBold !important; }
#pagination-previous-page { background: transparent !important;}
#pagination-previous-page svg, #pagination-first-page, #pagination-next-page, #pagination-last-page { fill: #334155;}
#pagination-first-page, #pagination-last-page, #pagination-next-page { background-color: #DEE5ED;}
// #add-camp-page .rdt_TableCell:last-child { justify-content: center;}
.rdt_TableRow { border-bottom:  1px solid rgba(0,0,0,0.08) !important;;}
.rdt_Table { padding: 20px; box-shadow: 0 3px 5px #0000000b; border: 1px solid #e2e8f0 !important;  border-radius: .6rem;}

.rdt_TableRow:nth-child(even) .rdt_TableCell { background: rgba(0, 0, 0, 0.03);}

.custom-danger-btn { background: $danger !important; color: #fff !important;} 
.btn-success { border: 1px solid $bg_orange !important; background: $bg_orange !important;}
.btn-success:hover { background: $d_orange !important; border: 1px solid $d_orange !important; color: $white !important;}
.custom-danger-btn:hover { background: $dred !important; border: 1px solid $dred !important;}

.confirmation-content__container.modal-body { padding: 40px 20px !important;} 
.hpSimplified-light { padding-bottom:20px !important;}

.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div { padding: 0 10px 0 0 !important;}
.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-light-white { background: none !important; text-align: right !important;}
.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-light-white p { font-weight: normal !important; display: inline-block; background: $danger !important; color: #fff !important; padding: 7px 25px !important; border-radius: 6px;} 
.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-light-white p:hover  { background: $dred !important;}

.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-theme-color { background: none !important; text-align: left !important;}
.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-theme-color p { font-weight: normal !important; display: inline-block; background: $bg_orange !important; color: #fff !important; padding: 7px 25px !important; border-radius: 6px;} 
.confirmation-content__container.modal-body .no-margin.row:nth-child(2) > div.bg-theme-color p:hover { background: $d_orange !important;}


#login-page { background: url(../img/login-bg.jpg) repeat left top; background-size: cover; background-position: center center; }  

.form-group label.common-label { margin-bottom: 6px !important;}
#login-page .form-control.common-form-control { height: 44px !important; border-radius: 6px !important; background: #F1F5F9 !important;
  padding: 10px 20px !important; box-shadow: none !important; font-family: $publicsans-regular !important; color: #334155 !important;}
  #login-page .form-control.common-form-control::placeholder { font-size: 14px !important; color: #334155 !important}
  #userName.common-form-control { font-size: 14px !important; font-family: $publicsans-regular !important;}
#login-page button.custom-theme-btn { background: $bg_orange !important; border: 1px solid $bg_orange !important;}
#login-page button.custom-theme-btn:hover { color: $white !important; background: $themeColor !important; border: 1px solid $themeColor !important; }

.nav-container .nav-item .nav-link {  padding: 14px 14px 14px 20px !important; margin-bottom: 4px;}
.nav-container .nav-item .nav-link.active { border-radius: 25px 0px 0px 25px; position: relative; border: none !important; background-color: #F1F5F9 !important;}
.nav-container .nav-item .nav-link:hover, .nav-item:hover { border-radius: 30px 0 0 30px; }
.navigation-container { width: 17rem !important;  padding-left: 1rem !important; }
.nav-container .nav-item { font-size: 0.975rem !important; }
.menu__content-container { margin-left: 17rem !important;}
.nav-container .nav-item .nav-link.active::before { content: "";
  width: 42px;
  height: 36px;
  margin-top: -40px;
  transform: rotate(90deg) scale(1.07);
  background-size: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -2px;}

.nav-container .nav-item .nav-link.active::after {
    content: "";
    width: 40px;
    height: 35px;
    margin-top: 50px;
    transform: scale(1.04);
    background-size: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='259.51' height='259.52' viewBox='0 0 259.51 259.52'%3E%3Cpath id='Path_143' data-name='Path 143' d='M8659.507,423.965c-.167-2.608.05-5.319-.19-8.211-.084-1.012-.031-2.15-.118-3.12-.113-1.25-.1-2.682-.236-4.061-.172-1.722-.179-3.757-.365-5.394-.328-2.889-.478-5.857-.854-8.61-.509-3.714-.825-7.252-1.38-10.543-.934-5.535-2.009-11.312-3.189-16.692-.855-3.9-1.772-7.416-2.752-11.2-1.1-4.256-2.394-8.149-3.687-12.381-1.1-3.615-2.366-6.893-3.623-10.493-1.3-3.739-2.917-7.26-4.284-10.7-1.708-4.295-3.674-8.078-5.485-12.023-1.145-2.493-2.5-4.932-3.727-7.387-1.318-2.646-2.9-5.214-4.152-7.518-1.716-3.16-3.517-5.946-5.274-8.873-1.692-2.818-3.589-5.645-5.355-8.334-2.326-3.542-4.637-6.581-7.039-9.848-2.064-2.809-4.017-5.255-6.088-7.828-2.394-2.974-4.937-5.936-7.292-8.589-3.027-3.411-6.049-6.744-9.055-9.763-2.4-2.412-4.776-4.822-7.108-6.975-3-2.767-5.836-5.471-8.692-7.854-3.332-2.779-6.657-5.663-9.815-8.028-2.958-2.216-5.784-4.613-8.7-6.6-3.161-2.159-6.251-4.414-9.219-6.254-3.814-2.365-7.533-4.882-11.168-6.89-4.213-2.327-8.513-4.909-12.478-6.834-4.61-2.239-9.234-4.619-13.51-6.416-4.1-1.725-8.11-3.505-11.874-4.888-4.5-1.652-8.506-3.191-12.584-4.47-6.045-1.9-12.071-3.678-17.431-5-9.228-2.284-17.608-3.757-24.951-4.9-7.123-1.112-13.437-1.64-18.271-2.035l-2.405-.2c-1.638-.136-3.508-.237-4.633-.3a115.051,115.051,0,0,0-12.526-.227h259.51Z' transform='translate(-8399.997 -164.445)' fill='%23f1f5f8'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 0px;
}

.bg-none { background: none !important;}
.logo-title { margin-bottom: 10px !important;}
.bg-lblue { background: $bg_blue !important; border: 1px solid $bg_blue !important;}
.txt-blue { color: $txt-blue;}
.title-theme-color { color: $txt-blue !important;}
.btn-secondary span { font-size: 20px !important; line-height: 0; position: relative; top: 2px;}
.inactive-btn.thrash-ico { background: none !important; padding: 0 !important;}
.inactive-btn svg { color: $danger; font-size: 16px;}

.modal-content { border-radius: 8px !important; overflow: hidden; border: none !important;}
.modal-header-container { background: $bg_blue;}
.lgrey-btn { background: $lgrey !important; font-size: 1rem !important; border: 1px solid $lgrey !important; padding: 6px 12px !important;}
.lgrey-btn:hover { background: $dkgrey !important; color: $white !important;} 
#mobileNumber.form-control.common-form-control,
#contactNo.form-control.common-form-control { padding: auto !important; border-radius: 0 !important; background: none !important;}

.patient-sec { background: $white; box-shadow: none !important;} 
.pat-date { font-size: 12px !important; color: rgba(0, 0, 0, 0.5);}
.patient-sec .title { font-family: $publicsans-bold; font-weight: normal !important; font-size: 16px !important;}
.patient-sec.card-contianer { padding: 15px !important;}
.patient-sec.card-contianer .sub-title { font-family: $publicsans-bold; font-size: 14px !important; }
.patient-sec.card-contianer p.status-text--small { font-family: $publicsans-medium; background: #f3f5f7; padding:8px 14px;
  margin-right: 12px; border-radius: 5px;  display: flex; align-items: center; justify-content: center;}
.patient-sec.card-contianer p .status-divider-plr { display: none;}
.patient-sec.card-contianer p.status-active { background: $themeColor; color: $white;}
.patient-sec.card-contianer p.status-text--small:hover { background:#F9802C !important; color: #fff !important;}

.patientSummaryReportTable p.status-text--small { font-family: $publicsans-medium; background: #f3f5f7; 
  padding:8px 14px;
  margin-right: 3px; border-radius: 5px;  display: flex; align-items: center; justify-content: center; }
.patientSummaryReportTable p .status-divider-plr { display: none;}
.patientSummaryReportTable p.status-active { background: $themeColor; color: $white;}
.patientSummaryReportTable p.status-text--small:hover { background:#F9802C !important; color: #fff !important;}


.delete-ico svg { background: $danger; color: $white; padding: 10px; width: 36px !important; height: 36px; border-radius: 5px;} 
.q-ico svg { font-size: 18px !important ;}

.title-sec { padding: 10px 10px!important;}
.back-arrow { width: auto !important; flex: inherit !important; max-width: none !important;}
.dash-btn { background: #f1f2f8 !important; color: #334155 !important; border: none !important; font-family: $publicsans-semiBold !important; padding: 20px !important;}
.list-pad { padding: 20px !important;}
.pat-icon { width: 100%; display: block; float: left; margin-bottom: 20px;}
.pat-icon img { max-width: 70px; background: #fff; padding: 10px; border-radius: 8px; box-shadow: 0 2px 1px rgba(0,0,0,0.1);}
.d-flex.justify-content-center.pat-list { position: relative; display: block !important; text-align: center; background: #f9f9f9; padding: 26px;
  border-radius: 6px; border: 1px solid #e2e8f0; }
.d-flex.justify-content-center.pat-list p { background: none !important; padding: 0 !important;}
.d-flex.justify-content-center.pat-list:hover p { color: $txt-blue !important;}

.report-btn { background: #6D6D6D !important; color: $white !important; font-family: $publicsans-bold !important; border: none !important;}
.report-color-btn { background: #fd7e14 !important; color: $white !important; font-family: $publicsans-bold !important; border: none !important;}
.rounded-6 { border-radius: .6rem !important;}
.form-fields { display: flex; flex-wrap: wrap; column-gap: 2%;}
.form-fields .form-group { flex: 0 1 32%;}
.form-fields .form-group .common-label { color: #334155; font-family: $publicsans-regular !important;}
.form-fields .form-group .common-label sup { color: red !important; font-size: 14px;}
.form-fields .form-group input { color: #647691 !important; font-family: $publicsans-regular !important;} 
.pat-title { font-size: 18px !important; margin-bottom: 20px !important; color: $txt-blue !important;}

.cancel-btn { background: $danger !important; color: $white !important; border: 1px solid $danger !important;  font-family: $publicsans-semiBold !important; padding: 8px 30px !important;}
.cancel-btn:hover { background: $dred !important; border: 1px solid $dred !important;}
.save-btn { background: $bg_blue !important; color: $white !important; border: 1px solid $bg_blue !important;  font-family: $publicsans-semiBold !important; padding: 8px 30px !important;}
.save-btn:hover { background: $themeColor !important; color: $white !important;}
.list-btns.form-group { margin-top: 25px !important;}
.list-btns.form-group button:first-child { margin-right: 10px; }

.form-fields.field-col-4 .form-group  { flex: 0 1 23.5%;}
.form-fields .form-group .common-select-drop-down__single-value { color: #647691 !important; font-family: $publicsans-regular !important; }
.form-fields .form-group .common-select-drop-down__placeholder { color: #647691 !important; font-family: $publicsans-regular !important; }

.sh-desc { font-family: $publicsans-medium !important;}
.qucik-title { font-family: $publicsans-bold;}

.full-width.form-group .common-label { color: #334155; font-family: $publicsans-regular !important;}
.full-width.form-group .common-label sup { color: red !important; font-size: 14px;}

.form-fields .form-group.wid-50 { flex: 0 1 49%;}
.re-check { margin-top: 35px;}
.check-box.col svg { width: 20px; height: 20px; }

textarea.form-control { color: #647691 !important; font-family: $publicsans-regular !important; height: 80px !important;}
.form-fields .wid-100 .common-label { font-family: $publicsans-regular !important;}

.form-fields .form-group svg.check-box  { width: 20px; height: 20px; }
.common-label.title-black.main-title { font-size: 18px !important; color: $black !important; font-family: $publicsans-medium !important;}
.check-field .common-label { font-family: $publicsans-regular !important; color: #334155;}

.modal-content-container.modal-body .form-control { font-family: $publicsans-regular !important;}
.modal-content-container.modal-body .common-select-drop-down__single-value { font-family: $publicsans-regular !important;}
.excel-ico { width: 18px; margin-right: 4px;}
.excel-btn { font-size: 15px !important;}
.excel-btn:hover { background: $d_orange !important; color: $white !important; border: 1px solid $d_orange !important;}


.change-password-form-container { border-radius: .6rem !important; box-shadow: none !important; box-shadow: 0 3px 5px rgba(0, 0, 0, 0.0431372549);
  border: 1px solid #e2e8f0 !important; }
.change-password-form-container label.common-label { font-family: $publicsans-regular !important; color: #334155 !important}
.change-password-form-container .input-group .form-control.common-form-control.form-control { font-size: 15px !important; font-family: $publicsans-regular !important; height: auto !important; padding: 10px 35px 10px 20px !important;}
.help-block.error-txt { font-size: 13px !important;}
.change-password-form-container .input-group .form-control.common-form-control.form-control::placeholder { font-size: 14px !important;}

.grading-box .pat-list { padding: 20px !important;}

.dash-box { box-shadow: 0 3px 5px #0000000b; padding: 20px; background-color: #fff; border: 1px solid #e2e8f0; border-radius: .6rem; position: relative; }
// .dash-box::after { position: absolute; content: ""; right: 0; top: 0; background: url(../img/circle.svg)no-repeat;}
.box-div::before {  background-color: #fff9; box-shadow: 0px 3px 5px #0000000b; content: ""; width: 80%; height: 100%; margin-top: .625rem; left: 0px;
  right: 0px;position: absolute; margin-left: auto; margin-right: auto; border-radius: .375rem;}

.dash-ico { display: block; margin-bottom: 25px;}
.dash-ico svg { color: #64748B;}
.dash-ico img {width: 50px;}
.pat-count { font-size: 34px; font-family: $publicsans-semiBold; line-height: 34px; color: #334155; margin-bottom: 5px;}
.pat-txt { font-size: 16px; color: #64748B; font-family: $publicsans-regular;}

.check-btn.add-video svg { height: 20px !important;}
#exampleFile { font-family: $publicsans-regular !important;}
.common-select-drop-down__control { font-family: $publicsans-regular !important;}

.pat-list .processing-btn { color:$txtdblue !important;}
.pat-list .dash-btn.success-btn { color: green !important;}
.pat-list .dash-btn.processing-btn { color: #037DE8 !important;}

span.num-bg { position: absolute;
  right: 10px; font-family: $publicsans-bold;
  font-size: 30px;
  color: #000;
  line-height: 30px;
  opacity: 0.06;
  bottom: 10px;}


@media (min-width: 320px) and (max-width: 1023px) {
  .menu__content-container svg.menu-icon{
    color: $themeColor;
  }

  .mobile-navigation__container { position: fixed !important; /* overflow: auto; */ } 
  .nav-container .nav-item .nav-link.active::before { display: none;}
  .nav-container .nav-item .nav-link.active::after { display: none;}
  .nav-container .nav-item .nav-link.active { border-radius: 0px;}
  .nav-container .nav-item .nav-link:hover, .nav-item:hover { border-radius: 0px;} 
  .verison-container { position: relative !important;}

}
// .color1 { background: linear-gradient(90deg,#ffbf96,#fe7096);}
// .color2 { background: linear-gradient(90deg,#90caf9,#047edf 99%); }
// .color3 { background: linear-gradient(90deg,#84d9d2,#07cdae);}
// .color4 { background: linear-gradient(90deg,#E67B95,#9C77EE); }

// .info-txt {  background: rgba(203, 213, 225, 0.5019607843); padding: 10px !important;}

.video-table .rdt_TableHeader { display: none;}


@media screen and (min-width:1280px) and (max-width:1460px) {
}
@media screen and (min-width:1024px) and (max-width:1279px) {
  .ll-pa-dd div{flex: 0 1 23%;}
  .ll-pa-dd{flex-wrap: wrap; row-gap: 15px; column-gap: 2%;}
  .form-fields.field-col-4 .form-group, .form-fields .form-group{flex: 0 1 32%;}

}
@media screen and (min-width:768px) and (max-width:1023px) {
  .dash-box{display: flex; align-items: center; margin-bottom: 20px;}
  .dash-box div{flex: 0 1 33%; margin: 0;}
  .box-div::before {display: none;}
  .ll-pa-dd div{flex: 0 1 23%;}
  .ll-pa-dd{flex-wrap: wrap; row-gap: 15px; column-gap: 2%;}
  .form-fields.field-col-4 .form-group, .form-fields .form-group{flex: 0 1 32%;}
  .no-mobile-plr div{margin-bottom: 15px;}

}
@media screen and (max-width:767px) {
  .dash-box{display: flex; align-items: center; margin-bottom: 20px;}
  .dash-box div{flex: 0 1 33%; margin: 0;}
  .box-div::before {display: none;}
  .pat-count{font-size: 22px;}
  .common-label{font-size: 16px!important;}
  .common-form-control, .common-form-control::placeholder{font-size: 15px!important;}
  .modal-content-container.modal-body .common-select-drop-down__single-value{font-size: 15px;}
  .internal-page__title{font-size: 17px!important; line-height: 24px!important; margin-bottom: 5px!important;}
  .btn{font-size: 15px!important; margin-bottom: 5px;}
  .ll-pa-dd div{flex: 0 1 32%;}
  .ll-pa-dd{flex-wrap: wrap; row-gap: 15px; column-gap: 2%;}
  .patient-sec.card-contianer p.status-text--small{margin: 0;}
  .pat-na{margin-bottom: 15px!important;}
  .delete-ico{margin-top: 20px;}
  .no-mobile-plr div{margin-bottom: 15px;}
  .form-fields.field-col-4 .form-group, .form-fields .form-group{flex: 0 1 49%;}
  .lo-lf{display: none;}

}
@media screen and (max-width:639px) {
  .mobile-navigation__container{width: 100%!important;}
  .ll-pa-dd div{flex: 0 1 49%;}

  .form-fields.field-col-4 .form-group, .form-fields .form-group{flex: 0 1 100%;}

}
.total-list .common-label { display: flex;}
.total-list .common-label p { width:310px; margin: 0;}
.total-list .common-label b { text-decoration: underline; font-weight: bold; font-family: PublicSans-Bold;}