.nav-container {
  display: block !important;
 // min-height: 100vh;
  // background-color: $themeColor;
  .nav-item {
    font-size: $fs-14px;
    cursor: pointer;
    @include setFont(normal, normal, normal, normal, left);
    .nav-link {
      font-family: $publicsans-medium;
      color: $white !important;
      border-right: $fs-4px solid transparent;
      @include setPadding($fs-12px, $fs-12px, $fs-12px, $fs-12px);
      &.active {
        //background-color: $white !important;
        color: $bg_blue !important;
        border-right: $fs-4px solid $white;
      }
    }
    @include mobileScreen {
      font-size: $fs-16px;
    }
  }
}

.nav-item:hover,
.nav-link:hover {
  background-color: rgba(255,255,255,0.05) !important;
  color: $themeColor !important;
}
