$white: #fff;
$gain-boro-white: #dddddd;
$white-smoke: #f5f5f5;

$white-light-smoke: #fafafa;
$black-peaurl: #0b0a10;
$white-smoke-light: #f6f6f6;
$black: #000000;
$nobel-grey: #9b9b9b;
$charcoal-grey: #4a4a4a;

$dimGrey: #848484;
$pictonblue: #62acd8;
$green: green;
$grey: #ddd;
$suvaGrey: #8a8a8a;
// $themeColor: #72d389;
//themeColor: '#e9782f';
$zirconGrey: #F1F2F3;
$chateauGreyColor: #9FA0A2;
$lightBlue: #42afef;
$lightWhite: #f7f7f7;
$danger: #DF3B3B;
$slategray: slategray;
$lightGrey: #eee;
$transparent: transparent;
$warning: #e88a02;
$greyLight: #daeade;
$light-theme: #6fbb81;

//
$themeColor: #373d85;
$txtdblue:#334155;
$bg_color:#F1F5F9;
$bg_orange:#F9802C;
$d_orange:#ed6e16;
$lgreen: #90D12C;
$dgreen: #70aa16;
$dgrey:#1E293B;
$dred:#ce3232;
$lgrey:#747474;
$dkgrey:#5f5f5f;
$bg_blue:#037DE8;
$txt-blue:#037DE8;
$title-theme-color:#037DE8;