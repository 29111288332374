@import "../../styleSheets/fonts";
@import "../../styleSheets/mixins";

.header-section__contianer {
  @include setPadding(
    0 !important,
    $fs-24px !important,
    0 !important,
    $fs-24px !important
  );
  position: fixed !important;
  top: 0;
  z-index: 999;
}

.header-container {
  position: fixed !important;
  top: 0;
  z-index: 99;
  @include setPadding($fs-12px, 0, $fs-12px, 0);
}

.navigation-container {
  width: 14rem;
  min-height: 100%;
  position: fixed !important;  
  height: 100vh;
  // overflow-y: scroll;
  // overflow-x: hidden;
  @include mobileScreen {
    display: none !important;
  }
}

.sidenav-menu {
  height: calc(100% - 175px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 30px;
  margin-right: -18px;
}

.sidenav-menu::-webkit-scrollbar-track {
  display: none !important;
}



/* Works on Firefox */
.sidenav-menu { scrollbar-width: thin; scrollbar-color: rgba(255, 255, 255, 0.2) #F1F5F9;}
/* Works on Chrome, Edge, and Safari */
.sidenav-menu::-webkit-scrollbar { width: 10px;}
.sidenav-menu::-webkit-scrollbar-track { background: #F1F5F9;}
.sidenav-menu::-webkit-scrollbar-thumb { background-color: rgba(255, 255, 255, 0.2); border-radius: 0px; border: 2px solid transparent;}

@media only screen and (max-width: 1023px) {
  .navigation-container { overflow-x: hidden;}
  .sidenav-menu{ margin-right: 0px; padding-top: 15px;}

}



.navigation__collapse-container {
  width: 4rem;
  background-color: $themeColor;
  height: 100vh;
  position: fixed !important;
}

.menu__content-container {
  margin-left: 14rem;
  min-height: 100vh;
  @include mobileScreen {
    margin-left: 0 !important;
  }
}

.menu__collapse-container {
  margin-left: 4rem;
  min-height: 100vh;
}

.common__icon-width {
  color: $themeColor;
  font-size: $fs-24px;
}

.header-collapse-width {
  width: calc(100% - 4rem) !important;
}

.header-width {
  width: calc(100% - 14rem) !important;
  @include mobileScreen {
    width: 100% !important;
  }
}

.logo__container {
  display: flex;
  width: $fs-80px;
  margin: auto;
  margin-top: $fs-25px;
}

.logo-title {
  font-family: $publicsans-bold;
  color: $white;
  font-size: $fs-18px;
}

.displayBar {
  display: none !important;
  cursor: pointer;
  @include mobileScreen {
    display: block !important;
  }
}

.profile-container {
  display: flex;
  align-items: center;
  width: $fs-42px;
  height: $fs-42px;
  border-radius: 50%;
  margin: auto;
  justify-content: center;
}
// ------------------- Mobile responsive ------------------
// @media only screen and (max-width: 600px) {
//   .navigation-container {
//     display: none !important;
//   }
//   .menu__content-container {
//     margin-left: 0 !important;
//   }
//   .header-width {
//     width: 100% !important;
//   }
//   .displayBar {
//     display: block !important;
//   }
// }



.mobile-navigation__container {
  position: absolute;
  width: 50%;
  background-color: $themeColor;
  left: 0;
  right: 0;
  z-index: 999;
  margin-top: $fs-72px;
  @include desktop{
    width: 30%;
  }
  @include mobileScreen{
    height: 90vh;
  }
}

.min-height-48px {
  min-height: $fs-48px;
  @include mobileScreen{
    min-height: $fs-72px;
  }
}

.profile-icon{
  font-size: $fs-32px;
}
.menu-icon{
  font-size: $fs-32px;
}

.verison-container{
  position: relative;
  bottom: 0;
  width: 100%;
  float: left;
  margin-top:10px;
  margin-bottom: 10px;
  padding-right: 1rem;
}

.logo-container {
  width: 9rem;
  margin: 0 auto;
  border-radius: 5px;
  padding: 0;
  background: #fff;
}

#login-page .shadow-lg { position: relative; z-index: 1; border-radius: 6px !important; overflow: hidden; padding: 20px;}
// #login-page .shadow-lg::after {
//   position: absolute;
//   display: block;
//   content: "";
//   left: 0;
//   top: 0;
//   width: 100%;
//   background: #0055AB;
//   height: 166px;
//   border-radius: 0 0 50% 50%;
//   z-index: -1;
// }
#login-page .bg-grey-medium { margin-bottom: 20px !important; background: none !important;}
.help-block {  color: $danger; font-size: 15px !important; font-family: $publicsans-regular;}
.login-img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}